<template>
      <v-container
        id="street-lighting"
        fluid
        tag="section"
    >
        <v-alert
      dense
      text
      type="success"
      dismissible
      v-model="alert"
    >
      Section B <strong>saved</strong> successfully.
    </v-alert>
        <v-row justify="center">
            <v-col
            cols="12"
            md="12"
            >

                <base-material-card>
                <template v-slot:heading>
                    <div class="display-2 font-weight-light">
                    Section B: Cabinetry
                    </div>
                  <div class="display-2 font-weight-light">
                    Ultimate Cabinetry Inc., 13567 Thrush St. NW, Andover, 55304 
                    </div>
                  <div class="display-2 font-weight-light">
                    Contact: Keith Gerads 763-767-3123
                    </div>
                  <div class="display-2 font-weight-light">
                    (Time Frame- 3 Weeks)
                    </div>
                </template>
                <v-form>
                    <v-container class="py-0">
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    style="width:50%;"
                                    label="Date"
                                    color="black"
                                    type="date"
                                />
                            </v-col>


                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 1 - First Name"
                                    color="black"
                                    v-model="this.selected_user.PrimaryFirstName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 1 - Last Name"
                                    color="black"
                                    v-model="this.selected_user.PrimaryLastName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 1 - Cell#"
                                    color="black"
                                    v-model="this.selected_user.CellNumber"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 1 - Email"
                                    color="black"
                                    v-model="this.selected_user.PrimaryEmailAddress"
                                />
                            </v-col>


                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 2 - First Name"
                                    color="black"
                                    v-model="this.selected_user.SecondaryFirstName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 2 - Last Name"
                                    color="black"
                                    v-model="this.selected_user.SecondaryLastName"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 2 - Cell#"
                                    color="black"
                                    v-model="this.selected_user.CellNumber"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Homeowner 2 - Email"
                                    color="black"
                                    v-model="this.selected_user.SecondaryEmailAddress"
                                />
                            </v-col>

                            <v-col
                            cols="12"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Address & Legal"
                                    color="black"
                                    v-model="this.selected_user.Address"
                                />
                            </v-col>


                            <v-col
                            cols="12"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Based on model at"
                                    color="black"
                                    v-model="section.based_on_model_at"
                                />
                            </v-col>
                            <v-col
                            cols="12"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'" label="Exclude following items in the model"
                                  v-model="section.exclude_following_items">
                                </v-text-field>
                            </v-col>

                            <v-col
                            cols="12"
                            md="4"
                            >
                               <v-select :readonly="permissions === 'read:homeowner'"
                                    :items="select_items_kitchen"
                                    item-text="item_text"
                                    item-value="item_text"
                                    label="Height Kitchen"
                                    v-model="section.height_kitchen"
                                ></v-select>
                            </v-col>
                             <v-col
                            cols="12"
                            md="4"
                            >
                               <v-select :readonly="permissions === 'read:homeowner'"
                                    :items="select_items_paint_grade"
                                    item-text="item_text"
                                    item-value="item_text"
                                    label="Paint Grade"
                                    v-model="section.paint_grade"
                                ></v-select>
                            </v-col>
                             <v-col
                            cols="12"
                            md="4"
                            >
                               <v-select :readonly="permissions === 'read:homeowner'"
                                    :items="select_items_door_style"
                                    item-text="item_text"
                                    item-value="item_text"
                                    label="Door Style"
                                    v-model="section.door_hinges"
                                ></v-select>
                            </v-col>

                             <span>
                                ALL CABINET DOOR & DRAWER HANDLES INCLUDED, W/${{this.price_section_b.column_34}} each allowance. ALL CABINETRY COMES WITH HIDDEN HINGES.
                             </span>

                             <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'" label="Kit Soft Close Hinges"
                                v-model="section.kit_soft_case_hinges">
                                </v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'" label="Kit Soft Close Drawer Tracks"
                                v-model="section.kit_soft_drawer_tracks">
                                </v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'" label="Bath Soft Case Hinges"
                                v-model="section.bath_soft_case_hinges">
                                </v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-text-field :readonly="permissions === 'read:homeowner'" label="Bath Soft Close Drawer Tracks"
                                v-model="section.bath_soft_drawer_tracks">
                                </v-text-field>
                            </v-col>

                            <span>
                                ALL CABINETRY COMES WITH STANDARD WHITE SHELVING (includes bathroom vanities)
                            </span>

                            <v-row>
                            <v-col
                            cols="12"
                            md="6"
                            >
                               <v-select :readonly="permissions === 'read:homeowner'"
                                    :items="select_items_ci_model"
                                    item-text="item_text"
                                    item-value="item_text"
                                    label="Center Island: Same as model listed above"
                                    v-model="section.center_island"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                               <v-select :readonly="permissions === 'read:homeowner'"
                                    :items="select_items_ci_paint_grade"
                                    item-text="item_text"
                                    item-value="item_text"
                                    label="Paint Grade Center Island"
                                    v-model="section.paint_grade_island"
                                ></v-select>
                            </v-col>

                            <span>
                                NOTE: CABINET BACKS ARE ALL OPEN TO SHEET ROCK WALL!
                            </span>
                            </v-row>

                            <v-col
                            cols="12"
                            class="text-center"
                            >
                            <span>
                                <h3>Optional Items</h3>
                            </span>
                            </v-col>

                            <v-col
                            cols="12"
                            md="6"
                            >
                               <v-select :readonly="permissions === 'read:homeowner'"
                                    :items="select_items_ci_paint_grade"
                                    item-text="item_text"
                                    item-value="item_text"
                                    label="Custom Exhaust Hood"
                                    v-model="section.exhaust_hood"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                               <v-select :readonly="permissions === 'read:homeowner'"
                                    :items="select_items_ci_paint_grade"
                                    item-text="item_text"
                                    item-value="item_text"
                                    label="Wood"
                                    v-model="section.wood"
                                ></v-select>
                            </v-col>

                            <v-col
                            cols="12"
                            md="6"
                            >
                               <v-select :readonly="permissions === 'read:homeowner'"
                                    :items="select_items_ci_paint_grade"
                                    item-text="item_text"
                                    item-value="item_text"
                                    label="6” Staggered Upper Kitchen Cabinet Options"
                                    v-model="section.six_inch_upper_kitchen_cabinent"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                               <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Locations"
                                    v-model="section.locations"
                                ></v-text-field>
                            </v-col>

                            <v-col
                            cols="12"
                            md="3"
                            >
                               <v-select :readonly="permissions === 'read:homeowner'"
                                    :items="select_items_ci_paint_grade"
                                    item-text="item_text"
                                    item-value="item_text"
                                    label="Glass Doors"
                                    v-model="section.glass_doors"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="3"
                            >
                               <v-select :readonly="permissions === 'read:homeowner'"
                                    :items="select_items_ci_paint_grade"
                                    item-text="item_text"
                                    item-value="item_text"
                                    label="With Grids"
                                    v-model="section.with_grids"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                               <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Locations"
                                    v-model="section.optional_locations"
                                ></v-text-field>
                            </v-col>




                            <v-col
                            cols="12"
                            md="6"
                            >
                               <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Super Susan"
                                    v-model="section.super_susan"
                                ></v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                               <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Sink Tip Outs (Kitchen Only)"
                                    v-model="section.sink_tips_out"
                                ></v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                               <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Tray Dividers"
                                    v-model="section.tray_dividers"
                                ></v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                               <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Spice Rack Inside Cabinet Door"
                                    v-model="section.spice_rack_inside"
                                ></v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                               <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Two Bin Recycle Center (Attached to door)"
                                    v-model="section.two_bin_recycle"
                                ></v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                               <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Rollouts in Cabinets"
                                    v-model="section.rollouts_in_cabinets"
                                ></v-text-field>
                            </v-col>
                             <v-col
                            cols="12"
                            md="6"
                            >
                               <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Shaker End Panels to Kitchen Cabinets"
                                    v-model="section.shaker_end"
                                ></v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                               <v-text-field :readonly="permissions === 'read:homeowner'"
                                    label="Locations"
                                    v-model="section.shaker_kitchen_locations"
                                ></v-text-field>
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                               <v-textarea
                                    label="Bath Vanities"
                                    v-model="section.bath_vanities"
                                ></v-textarea>
                            </v-col>
                            <v-col
                            cols="12"
                            md="6"
                            >
                                <v-select :readonly="permissions === 'read:homeowner'"
                                    :items="select_items_ci_paint_grade"
                                    item-text="item_text"
                                    item-value="item_text"
                                    label="Paint Grade Bath"
                                    v-model="section.bath_paint_grade"
                                ></v-select>
                            </v-col>


                            <v-col
                            cols="12"
                            md="3"
                            >
                            <span>Fireplace</span>
                            </v-col>
                            <v-col
                            cols="12"
                            md="3"
                            >
                                <v-select :readonly="permissions === 'read:homeowner'"
                                    :items="select_items_ci_paint_grade"
                                    item-text="item_text"
                                    item-value="item_text"
                                    label="Mantel - Same as model listed above"
                                    v-model="section.mantel"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="3"
                            >
                                <v-select :readonly="permissions === 'read:homeowner'"
                                    :items="select_items_ci_paint_grade"
                                    item-text="item_text"
                                    item-value="item_text"
                                    label="Optional Paint Grade"
                                    v-model="section.mantel_paint_grade"
                                ></v-select>
                            </v-col>
                            <v-col
                            cols="12"
                            md="3"
                            >
                                <v-select :readonly="permissions === 'read:homeowner'"
                                    :items="select_items_ci_paint_grade"
                                    item-text="item_text"
                                    item-value="item_text"
                                    label="Optional Build-Ins Paint Grade"
                                    v-model="section.optional_build_ins"
                                ></v-select>
                            </v-col>



                            <v-col
                            cols="12"
                            md="3"
                            >
                            <span>Laundry Tub, (Std is Fiberglass)</span>
                            </v-col>
                            <v-col
                            cols="12"
                            md="3"
                            >
                                <v-select :readonly="permissions === 'read:homeowner'"
                                    :items="select_items_ci_paint_grade"
                                    item-text="item_text"
                                    item-value="item_text"
                                    label="Optional: Laundry base cabinet;"
                                    v-model="section.optional_laundry_cabinet"
                                ></v-select>
                            </v-col>

                            <v-col
                            cols="12"
                            md="3"
                            >
                            <span>Coving above upper cabinets</span>
                            </v-col>
                            <v-col
                            cols="12"
                            md="3"
                            >
                                <v-select :readonly="permissions === 'read:homeowner'"
                                    :items="select_items_ci_paint_grade"
                                    item-text="item_text"
                                    item-value="item_text"
                                    label="Stack (STD.)"
                                    v-model="section.stack"
                                ></v-select>
                            </v-col>
                     <v-dialog
                        v-model="dialog"
                        max-width="50%"
                      >
                           </v-dialog>

                        </v-row>
                     </v-container>
                </v-form>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from "axios";

export default {
    name:'VendorSelectionsUltimateCabinetry',
     data () {
      return {
        home_id: localStorage.getItem("homeid"),
        alert:false,
        dialog: null,
        valid: null,
        staff_email: 'steve@skelleymarketing.com',
        section_id: "B",
        add_vendor_email:'',
        vendor_email: [],
        selected_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
        default_vendor: {
            ItemId:0,
            Vendor: '',
            Name:'',
            Email: '',
            ModifiedDate:''
        },
        price_section_b: {
          column_34: ''
        },
      selected_user: {
          PrimaryEmailAddress:'',
          SecondaryEmailAddress:'',
          PrimaryFirstName: '',
          PrimaryLastName: '',
          SecondaryFirstName: '',
          SecondaryLastName: '',
          Address:'',
          City:'',
          State: '',
          Zip: '',
          BuildStart: '',
          BuildEnd:'',
          CellNumber:'',
          HomeNumber: '',
          WorkPhone:'',
          Role:'',
          Agent:''
      },
        section: {
          home_owner_1_first_name:'',
          home_owner_1_last_name:'',
          home_owner_1_cell_number:'',
          home_owner_1_email:'',
          home_owner_2_first_name:'',
          home_owner_2_last_name:'',
          home_owner_2_cell_number:'',
          home_owner_2_email:'',
          address:'',
          based_on_model_at:'',
          exclude_following_items:'',
          height_kitchen:'',
          paint_grade:'',
          door_hinges:'',
          kit_soft_case_hinges:'',
          kit_soft_drawer_tracks:'',
          bath_soft_case_hinges:'',
          bath_soft_drawer_tracks:'',
          center_island:'',
          paint_grade_island:'',
          exhaust_hood:'',
          wood:'',
          six_inch_upper_kitchen_cabinent:'',
          locations:'',
          glass_doors:'',
          with_grids:'',
          optional_locations:'',
          super_susan:'',
          sink_tips_out:'',
          tray_dividers:'',
          spice_rack_inside:'',
          two_bin_recycle:'',
          rollouts_in_cabinets:'',
          shaker_end:'',
          shaker_kitchen_locations:'',
          bath_vanities:'',
          bath_paint_grade:'',
          mantel:'',
          mantel_paint_grade:'',
          optional_build_ins:'',
          optional_laundry_cabinet:'',
          stack:''
        },
             select_items_kitchen: [
                {
                    item_text:'STD 42 INCH',
                    item_value:1
                },
                {
                    item_text:'OPTIONAL 56 INCH',
                    item_value:2
                }
            ],
             select_items_paint_grade: [
                {
                    item_text:'Yes',
                    item_value:1
                },
                {
                    item_text:'No',
                    item_value:2
                }
            ],

            select_items_ci_paint_grade: [
                {
                    item_text:'Yes',
                    item_value:1
                },
                {
                    item_text:'No',
                    item_value:2
                },
                {
                    item_text:'Undecided',
                    item_value:3
                }
            ],

            select_items_ci_model: [
                {
                    item_text:'Yes',
                    item_value:1
                },
                {
                    item_text:'No',
                    item_value:2
                },
                {
                    item_text:'Undecided',
                    item_value:3
                }
            ],

            select_items_door_style: [
                {
                    item_text:'Shaker Standard',
                    item_value:1
                },
            ],
            }
     },
    created () {
      this.getSection()
      this.getHomePrice()
      this.getVendorEmail()
      this.getUserInfo()
    },
    methods:{

        getSection() {
            axios
            .get(process.env.VUE_APP_GET_SECTION_URL,
            {
            params: {
                home_id: this.home_id,
                section_id: this.section_id
            }
            })
            .then(response => {
                const { data } = response
                if (data) {
                    this.section = JSON.parse(data['JsonDetails'])
                }
            })
            .catch(error => {
            console.log(error)
            })
        },
        getHomePrice(){
            axios
            .get(process.env.VUE_APP_GET_HOME_SECTION_PRICES_URL,
            {
              params: {
                home_id: this.home_id
            }
            })
            .then(response => {

                if (response.data)
                {
                    var result = response.data
                    this.price_section_b = JSON.parse(result['SectionB']) || {}
                }
            })
            .catch(error => {
            console.log(error)
            })
        },
        getUserInfo(){
            axios
            .get(process.env.VUE_APP_GET_USER_URL_INFO,
            {
              params: {
                home_id: this.home_id
            }
            })
            .then(response => {
                if (response.data)
                {
                    var result = response.data
                    this.selected_user = result[0]
                }
            })
            .catch(error => {
            console.log(error)
            })
        },
        getVendorEmail(){
          axios.get(process.env.VUE_APP_GET_VENDOR_EMAIL_URL,
          {
            // params: {
            // }
          }
          )
          .then(response => {
            this.vendor_email = response.data
          })
          .catch(error => {
            console.log(error)
          })
        },
        updateSection(){
            var body ={
              'home_id':this.home_id,
              'section_id': this.section_id,
              "section":JSON.stringify(this.section),
            }

            axios
            .post(process.env.VUE_APP_UPDATE_SECTION_URL,body)
            .then(response => {
                console.log(response.data)
                this.alert = true
            })
            .catch(error => {
            console.log(error)
            })
        },
        sectionComplete(){
            var body ={
              'home_id':this.home_id,
              'section_id': this.section_id,
              "section":JSON.stringify(this.section),
            }

            axios
            .post(process.env.VUE_APP_UPDATE_SECTION_TWO_URL,body)
            .then(response => {
                console.log(response.data)
                this.alert = true
                this.sendEmail([this.homeowner_emailid],this.staff_email,"home_section_submit_notification",this.this.selected_user.PrimaryFirstName, this.section_id)
            })
            .catch(error => {
            console.log(error)
            })
          this.add_vendor_email = Object.assign({}, this.add_vendor_email)
          this.editedIndex = -1
          this.close()
        },
        close(){
          this.dialog = false
          this.$nextTick(() => {
            this.add_vendor_email = Object.assign({}, this.add_vendor_email)
            this.editedIndex = -1
          })
        },
        sendEmail(to,from,template,content,section){

        var email_body ={
          "to": to,
          "from": from,
          "template_name": template,
          "template_data": {
            "content": content,
            "section": section
          }
        }

        axios
        .post(process.env.VUE_APP_SEND_EMAIL_URL,email_body)
        .then(response => {
          console.log(response.data)
        })
        .catch(error => {
          console.log(error)
        })

      }
    },
    computed: {
      homeowner_emailid() {
        return this.selected_user.PrimaryEmailAddress
      },
      permissions() {
            return localStorage.getItem('permissions')
      },
    }
}
</script>